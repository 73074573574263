import {makeAutoObservable} from "mobx";

import {Persistable} from "../storage/persistable";
import {SortOption} from "@/common/models/util";
import {OrderSearchFilter} from "@/common/models/order";

class OrderStore implements Persistable{
    persistedSortOptions: SortOption[] = []
    persistedFilterQuery: OrderSearchFilter | {} = {}

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
        this.loadFromLocalStorage()
    }

    persistSortOptions(options) {
        this.persistedSortOptions = options
        localStorage.setItem('sortOptions', JSON.stringify(this.persistedSortOptions))
    }

    persistFilterQuery(options) {
        this.persistedFilterQuery = options
        localStorage.setItem('filterQuery', JSON.stringify(this.persistedFilterQuery))
    }



    loadFromLocalStorage(): void {
        const sortOptions = localStorage.getItem('sortOptions')
        const filterQuery = localStorage.getItem('filterQuery')

        if (sortOptions) {
            this.persistedSortOptions = JSON.parse(sortOptions)
        }
        if(filterQuery){
            this.persistedFilterQuery = JSON.parse(filterQuery)
        }
    }
}

const orderStore = new OrderStore()

export default orderStore