import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {Col, Container, Dropdown, Form, Row} from "react-bootstrap";
import {useNavigate} from 'react-router-dom'

import OrderItemChangeHistoryView from './OrderItemChangeHistoryView'
import SearchableSelect from './SearchableSelect'
import OrderService from '@/common/api/OrderService'
import { OrderItemChangeApiResponse } from "@/common/models/api/v0/order.dto"
import {Roles} from "@/common/constants/roles";
import {Statuses} from "@/common/constants/statuses";
import DefaultButton from "@/components/buttons/DefaultButton";
import {
    clientAccess,
    deliveryTypeOptions,
    fields,
    parcelTypeOptions,
    RouteCellOptions
} from "@/pages/order/components/options";
import {orderStatusMap} from "@/components/controls/order-action/actions";
import {buildOrderItemUpdateDto, checkIfEmpty, convertToTimeZone} from "@/common/utils/utils";
import CityAsyncSelect from '@/components/controls/select/CityAsyncSelect'
import {Formats} from "@/common/constants/dateFormat";
import { cellOptions } from './options';
import RouteCellSelect from "@/pages/order/components/RouteCellSelect";

const OrderItemDetailsView = ({orderItem, readOnly = true, role = Roles.Client, onEdit = () => {}}) => {
    const {data, isFetching, error, refetch} = useQuery<OrderItemChangeApiResponse>('orderItemChangeHistory',
        () => OrderService.getOrderItemChangeHistory(orderItem?.id))
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState(null)
    const [editMode, setEditMode] = useState<boolean>(false)
    const [values, setValues] = useState({
        ...orderItem,
        estimatedDeliveryDate: convertToTimeZone(orderItem?.estimatedDeliveryDate, false, 'yyyy-MM-dd\'T\'HH:mm:ss')
    });
    const [deliveryReceiver, setDeliveryReceiver] = useState(orderItem?.deliveryReceiver)
    const [deliverySender, setDeliverySender] = useState(orderItem?.deliverySender)
    const [deliveryType, setDeliveryType] = useState(deliveryTypeOptions.filter(item => item.value === orderItem?.deliveryType)[0])
    const [parcelType, setParcelType] = useState(parcelTypeOptions.filter(item => item.value === orderItem?.parcelType)[0])
    const [status, setStatus] = useState(null)

    const navigate = useNavigate()

    const currentStatus = orderItem?.status
    const cancelEdit = () => {
        setValues({
            ...orderItem,
            estimatedDeliveryDate: convertToTimeZone(orderItem?.estimatedDeliveryDate, false, 'yyyy-MM-dd\'T\'HH:mm:ss')
        })
        setEditMode(false)
        setDeliveryReceiver(orderItem?.deliveryReceiver)
        setDeliverySender(orderItem?.deliverySender)
        setDeliveryType(deliveryTypeOptions.filter(item => item.value === orderItem?.deliveryType)[0])
        setParcelType(parcelTypeOptions.filter(item => item.value === orderItem?.parcelType)[0])
        setStatus(null)
        setErrors(null)
    }

    const getAccess = (field: string = null): boolean => {
        if (!editMode) {
            return false
        }
        if (role === Roles.Manager) {
            return true
        }
        if (orderItem?.status !== "NEW") {
            return false
        }
        const isAccessible = clientAccess.get(field)
        return !!isAccessible;
    }
    const handleApprove = async () => {
        try {
            setIsLoading(true)
            await OrderService.changeStatus(values.id, 'CONFIRM_NEW_ORDER')
            setValues({...values, status: "READY_FOR_PICKUP"});
            await refetch()
        } catch (e) {
            setIsLoading(true)
            setErrors(e)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSave = async () => {
        try {
            setIsLoading(true)
            const updatedOrderItem = buildOrderItemUpdateDto(values, deliveryType, parcelType, deliveryReceiver)
            await OrderService.updateOrderItem(updatedOrderItem, values.id)
            if(status !== null)
                await OrderService.changeStatus(values.id, status.event)
            setEditMode(false)
            onEdit()
        } catch (e) {
            setIsLoading(true)
            setErrors(e)
        } finally {
            setIsLoading(false)
        }
    }

    const senderName = orderItem?.deliverySender?.isCompany
        ? orderItem?.deliverySender?.company?.name
        : orderItem?.deliverySender?.contactName

    const receiverName = orderItem?.deliveryReceiver?.isCompany
        ? orderItem?.deliveryReceiver?.company?.name
        : orderItem?.deliveryReceiver?.contactName
    return (
        <div>
            <Container>
                <Form>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Row>
                                <Form.Label>ДАТА СОЗДАНИЯ: {orderItem
                                    ? (orderItem?.createDate && convertToTimeZone(orderItem?.createDate, false,Formats.DATE_DMY_TIME))
                                    : ''}
                                </Form.Label>
                            </Row>
                            <Row><Form.Label>ОТПРАВИТЕЛЬ</Form.Label></Row>
                            <Form.Label>Наименование компании:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                value={checkIfEmpty(deliverySender?.company?.name)}
                                onChange={(e) =>
                                    setDeliverySender({...deliverySender,
                                        company: {
                                            ...deliverySender.company,
                                            name: e.target.value
                                        }
                                    })
                                }
                                disabled={true}
                                placeholder={senderName}
                            />
                            <Form.Label>Город отправителя:</Form.Label>
                            <CityAsyncSelect
                                name="sender"
                                readOnly={readOnly}
                                defaultValue={{
                                    value: checkIfEmpty(deliverySender?.address?.city?.id),
                                    label: checkIfEmpty(deliverySender?.address?.city?.name),
                                }}
                                onChange={(option) => setDeliverySender({ ...deliverySender,
                                    address: {
                                        ...deliverySender.address,
                                        city: {
                                            id: option.value,
                                            name: option.label,
                                        }
                                    }
                                })}
                            />
                            <Form.Label>Улица:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                value={checkIfEmpty(deliverySender?.address?.street)}
                                onChange={(e) =>
                                    setDeliverySender({...deliverySender,
                                        address: {
                                            ...deliverySender.address,
                                            street: e.target.value
                                        }
                                    })
                                }
                                disabled={true}
                                placeholder={orderItem?.deliverySender?.address?.addressString}
                            />
                            <Form.Label>Дом:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                value={checkIfEmpty(deliverySender?.address?.building)}
                                onChange={(e) =>
                                    setDeliverySender({...deliverySender,
                                        address: {
                                            ...deliverySender.address,
                                            building: e.target.value
                                        }
                                    })
                                }
                                disabled={true}
                                placeholder={orderItem?.deliverySender?.address?.addressString}
                            />
                            <Form.Label>Офис/кв:</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                value={checkIfEmpty(deliverySender?.address?.apartment)}
                                onChange={(e) =>
                                    setDeliverySender({...deliverySender,
                                        address: {
                                            ...deliverySender.address,
                                            apartment: e.target.value
                                        }
                                    })
                                }
                                disabled={true}
                                placeholder={orderItem?.deliverySender?.address?.addressString}
                            />
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Контактное лицо:</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={checkIfEmpty(deliverySender?.contactName)}
                                        onChange={(e) =>
                                            setDeliverySender({...deliverySender,
                                                contactName: e.target.value
                                            })
                                        }
                                        disabled={true}
                                        placeholder={orderItem?.deliverySender?.contactName}
                                    />
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Номер телефона:</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={checkIfEmpty(deliverySender?.phone)}
                                        onChange={(e) =>
                                            setDeliverySender({...deliverySender,
                                                phone: e.target.value
                                            })
                                        }
                                        disabled={true}
                                        placeholder={orderItem?.deliverySender?.phone}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label>Тип доставки:</Form.Label>
                                <Dropdown className="w-100">
                                    <Dropdown.Toggle
                                        className="w-100 border-rounded-1"
                                        id={"deliveryType-dropdown"}
                                        variant={"primary"}
                                        disabled={!getAccess(fields.senderDeliveryType)}
                                    >
                                        {deliveryType === "*" ? ("Выберите тип доставки") : (deliveryType?.text)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu
                                        className="w-100 p-0"
                                    >
                                        {deliveryTypeOptions.slice(1, deliveryTypeOptions.length).map((type) =>
                                            <Dropdown.Item
                                                key={type.value}
                                                className="w-100 text-center"
                                                onClick={() => {
                                                    setDeliveryType(type)
                                                }}>{type.text}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Row>
                            <Row>
                                <Form.Label>Тип посылки:</Form.Label>
                                <Dropdown className="w-100">
                                    <Dropdown.Toggle
                                        className="w-100 border-rounded-1"
                                        id={"parcelType-dropdown"}
                                        variant={"primary"}
                                        disabled={!getAccess(fields.senderParcelType)}
                                    >
                                        {parcelType === "*" ? ("Выберите тип посылки") : (parcelType?.text)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu
                                        className="w-100 p-0"
                                    >
                                        {parcelTypeOptions.slice(1, parcelTypeOptions.length).map((type) =>
                                            <Dropdown.Item
                                                key={type.value}
                                                className="w-100 text-center"
                                                onClick={() => {
                                                    setParcelType(type)
                                                }}>{type.text}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Row>
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Маршрут:</Form.Label>
                                    <RouteCellSelect
                                        key={values?.route}
                                        items={Object.keys(RouteCellOptions)}
                                        disabled={!getAccess()}
                                        defaultItem={values?.route}
                                        onChange={(route) => {
                                            if (route) {
                                                setValues(prev => ({
                                                    ...prev,
                                                    route: route,
                                                    cell: RouteCellOptions[route]
                                                }))
                                            } else {
                                                setValues(prev => ({
                                                    ...prev,
                                                    route: null,
                                                }))
                                            }

                                        }}
                                    />
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Ячейка:</Form.Label>
                                    <RouteCellSelect
                                        key={values?.cell}
                                        items={cellOptions}
                                        disabled={!getAccess()}
                                        defaultItem={values?.cell}
                                        onChange={(cell) => {
                                            if (cell) {
                                                setValues(prev => ({
                                                    ...prev,
                                                    cell: cell
                                                }))
                                            } else
                                                setValues(prev => ({
                                                    ...prev,
                                                    cell: null,
                                                }))
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="mb-3">
                                    <Form.Label>Курьер:</Form.Label>
									<SearchableSelect
										orderItem={orderItem}
										readOnly={!getAccess()
											|| !Statuses.courierFieldAccessibleStatuses.includes(currentStatus)}
                                        onChange={(selectedOption) => {
                                            if (typeof selectedOption === "string") {
                                                orderItem.courier = selectedOption;
                                            } else {
                                                orderItem.courier = selectedOption.value;
                                            }
                                            setValues({...values,
                                                courier: orderItem.courier
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="mb-3">
                                    <Form.Label>Дата доставки:</Form.Label>
                                    <Form.Control
                                        className="w-100 d-flex justify-content-center"
                                        required
                                        type="datetime-local"
                                        value={checkIfEmpty(values?.estimatedDeliveryDate)}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                estimatedDeliveryDate: e.target.value
                                            })
                                        }
                                        }
                                        disabled={!getAccess()}
                                    />
                                </Col>

                            </Row>
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Статус:</Form.Label>

                                    <Dropdown className="w-100">
                                        <Dropdown.Toggle
                                            className="w-100 border-rounded-1"
                                            id={"deliveryType-dropdown"}
                                            variant={"primary"}
                                            disabled={!(getAccess() && orderStatusMap[orderItem?.status])}
                                        >
                                            {status === null ? ("Изменить статус") : (status.name)}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu
                                            className="w-100 p-0"
                                        >
                                            {
                                                orderStatusMap[orderItem?.status]?.events[0].name && (
                                                    <Dropdown.Item
                                                        key={orderItem?.status}
                                                        className="w-100 text-center"
                                                        onClick={() => {
                                                            setStatus(orderStatusMap[orderItem?.status]?.events[0])
                                                        }}>
                                                        {orderStatusMap[orderItem?.status]?.events[0].name}
                                                    </Dropdown.Item>
                                                )
                                            }

                                            <Dropdown.Item
                                                className="w-100 text-center"
                                                key={"clean-status"}
                                                onClick={() => {
                                                    setStatus(null)
                                                }}
                                            >
                                                <span className="text-danger">Очистить</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Стоимость:</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={checkIfEmpty(values?.calculatedCost)}
                                        onChange={(e) =>
                                            setValues({...values,
                                                calculatedCost: e.target.value
                                            })
                                        }
                                        disabled={!getAccess()}
                                        placeholder={orderItem?.calculatedCost}
                                    />
                                </Col>
                                <Col md={12} className="mb-3">
                                    <Form.Label>Комментарии:</Form.Label>
                                    <Form.Control
                                        id={"comments"}
                                        name={"comments"}
                                        placeholder={checkIfEmpty(orderItem?.comments)}
                                        as={"textarea"}
                                        style={{minHeight: "5.5rem"}}
                                        disabled={!getAccess(fields.receiverOrderComments)}
                                        onChange={(e) => {
                                            setValues({...values,
                                                comments: e.target.value
                                            })
                                        }}
                                        value={checkIfEmpty(values?.comments)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {
                                        editMode ? (
                                                <Row className="d-flex justify-content-between">
                                                    {
                                                        (errors !== null) && (<Col md={10}><span className="d-flex text-danger">{errors?.message}</span></Col>)
                                                    }

                                                    <Col md={4} className="d-flex justify-content-start">
                                                        <DefaultButton
                                                            key={"saveOrder"}
                                                            variant={"success"}
                                                            onClick={handleSave}
                                                            isLoading={isLoading}
                                                        >Сохранить
                                                        </DefaultButton>
                                                    </Col>
                                                    <Col md={7} className="d-flex justify-content-end">
                                                        <DefaultButton
                                                            key={"cancelOrder"}
                                                            variant={"danger"}
                                                            onClick={cancelEdit}
                                                            isLoading={isLoading}
                                                        >
                                                            Отменить редактирование
                                                        </DefaultButton>
                                                    </Col>
                                                </Row>

                                        ) : (
                                            <DefaultButton
                                                key={"editOrder"}
                                                variant={"primary"}
                                                onClick={() => {
                                                    setEditMode(true)
                                                }}
                                                disabled={!(role === Roles.Manager ? true : (orderItem?.status === "NEW" && true))}
                                                isLoading={isLoading}
                                            >Редактировать
                                            </DefaultButton>
                                        )
                                    }
                                </Col>
                                <Col md={9} className="mt-3">
                                    <div className="d-flex">
                                    {Statuses.printAccessibleStatuses.includes(values?.status) && (
                                        <DefaultButton
                                            bordered 
                                            variant="light"
                                            onClick={() => {
                                                navigate('/orders-print', { state: { orderItem } })
                                            }}
                                        >
                                            Сформировать наклейку
                                        </DefaultButton>                     
                                    )}
                                    {values?.status === "NEW" && role === Roles.Client && (
                                        <DefaultButton
                                            className="mx-2"
                                            key={"editOrder"}
                                            variant={"primary"}
                                            isLoading={isLoading}
                                            onClick={handleApprove}
                                            disabled={!(values.status === "NEW")}
                                        >Подтвердить заказ
                                        </DefaultButton>
                                    )}                        
                                    </div>
                                </Col>
                            </Row>

                        </Col>

                        <Col md={6} className="mb-3">
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Label>Заказ: {orderItem?.id}</Form.Label>
                                </Col>
                            </Row>
                            <Row><Form.Label>ПОЛУЧАТЕЛЬ</Form.Label></Row>
                            <Row>
                                <Form.Label>Наименование компании:</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={checkIfEmpty(deliveryReceiver?.company?.name)}
                                    onChange={(e) =>
                                        setDeliveryReceiver({...deliveryReceiver,
                                            company: {
                                                ...deliveryReceiver.company,
                                                name: e.target.value
                                            }
                                        })
                                    }
                                    disabled={!getAccess(fields.receiverName)}
                                    placeholder={receiverName}
                                />
                                <Form.Label>Город отправителя:</Form.Label>
                                <div className="p-0">
                                    <CityAsyncSelect
                                        name="receiver"
                                        defaultValue={{
                                            value: checkIfEmpty(deliveryReceiver?.address?.city?.id),
                                            label: checkIfEmpty(deliveryReceiver?.address?.city?.name),
                                        }}
                                        readOnly={!getAccess(fields.receiverAddress)}
                                        onChange={(option) => setDeliveryReceiver({ ...deliveryReceiver,
                                            address: {
                                                ...deliveryReceiver.address,
                                                city: {
                                                    id: option.value,
                                                    name: option.label,
                                                }
                                            }
                                        })}
                                    />
                                </div>
                                <Form.Label>Улица:</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={checkIfEmpty(deliveryReceiver?.address?.street)}
                                    onChange={(e) =>
                                        setDeliveryReceiver({...deliveryReceiver,
                                            address: {
                                                ...deliveryReceiver.address,
                                                street: e.target.value
                                            }
                                        })
                                    }
                                    disabled={!getAccess(fields.receiverAddress)}
                                    placeholder={orderItem?.deliveryReceiver?.address?.addressString}
                                />
                                <Form.Label>Дом:</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={checkIfEmpty(deliveryReceiver?.address?.building)}
                                    onChange={(e) =>
                                        setDeliveryReceiver({...deliveryReceiver,
                                            address: {
                                                ...deliveryReceiver.address,
                                                building: e.target.value
                                            }
                                        })
                                    }
                                    disabled={!getAccess(fields.receiverAddress)}
                                    placeholder={orderItem?.deliveryReceiver?.address?.addressString}
                                />
                                <Form.Label>Офис/кв:</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={checkIfEmpty(deliveryReceiver?.address?.apartment)}
                                    onChange={(e) =>
                                        setDeliveryReceiver({...deliveryReceiver,
                                            address: {
                                                ...deliveryReceiver.address,
                                                apartment: e.target.value
                                            }
                                        })
                                    }
                                    disabled={!getAccess(fields.receiverAddress)}
                                    placeholder={orderItem?.deliveryReceiver?.address?.addressString}
                                />
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Label>Контактное лицо:</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            value={checkIfEmpty(deliveryReceiver?.contactName)}
                                            onChange={(e) =>
                                                setDeliveryReceiver({...deliveryReceiver,
                                                    contactName: e.target.value
                                                })
                                            }
                                            disabled={!getAccess(fields.receiverContactName)}
                                            placeholder={orderItem?.deliveryReceiver?.contactName}
                                        />
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Label>Номер телефона:</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            value={checkIfEmpty(deliveryReceiver?.phone)}
                                            onChange={(e) =>
                                                setDeliveryReceiver({...deliveryReceiver,
                                                    phone: e.target.value
                                                })
                                            }
                                            disabled={!getAccess(fields.receiverPhone)}
                                            placeholder={orderItem?.deliveryReceiver?.phone}
                                        />
                                    </Col>
                                </Row>

                                <hr/>

                                <Row style={{maxHeight: '570px', overflowY: 'auto'}}>
                                    <Row><Form.Label>История изменений</Form.Label></Row>
                                    <OrderItemChangeHistoryView
                                        orderItemChanges={data ? data.orderItemChanges : []}
                                        isLoading={isFetching}
                                        error={error}
                                    />
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    );
}

export default OrderItemDetailsView