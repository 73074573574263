import { RouteObject } from "react-router-dom"


import PrivateRoute from "../wrappers/PrivateRoute";
import SignInPage from "@/pages/signin"
import SignUpPage from "@/pages/signup"
import Home from "@/pages/home"
import OrderViewPage from "@/pages/order"
import ReportPage from "@/pages/report"
import Layout from "../../Layout"
import AuthProvider from "../providers/AuthProvider";
import TelegramOrderViewPage from "@/telegram/pages/order"
import TelegramProvider from "../providers/TelegramProvider"
import TelegramLayout from "@/telegram/TelegramLayout"
import TelegramOrderActionPage from "@/telegram/pages/order-action"
import CourierViewPage from "@/pages/courier"
import AuthorizedRoute from "../wrappers/AuthorizedRoute"
import { Roles } from "../constants/roles"
import OnboardViewPage from "@/pages/onboard";
import RequiresOnboardingRoute from "@/common/wrappers/RequiresOnboardingRoute";
import OrderCreatePage from "@/pages/order-create"
import OrderPrintPage from "@/pages/order-print"
import OrderProvider from "@/common/providers/OrderProvider";
import OrderListPrintPage from "@/pages/order-list-print"

const routes: RouteObject[] = [
	{
		element: <AuthProvider />,
    	children: [
    		{
				element: <Layout />,
				children: [
					{
						element: <PrivateRoute />,
						children: [
							{
								element: <RequiresOnboardingRoute />,
								children: [
									{
										element: <OrderProvider/>,
										children: [
											{path: '/orders', element: <OrderViewPage/>},
										]
									},
									{ path: '/', element: <Home />, },
								]
							},
							{
								element: <AuthorizedRoute allowedFor={Roles.Manager} />,
								children: [
									{ path: '/couriers', element: <CourierViewPage /> },
									{ path: '/reports', element: <ReportPage /> },
								]
							},
							{
								element: <AuthorizedRoute allowedFor={Roles.Client}/>,
								children: [
									{ path: '/orders-create', element: <OrderCreatePage /> },
									{ path: '/onboard', element: <OnboardViewPage /> },
								]
							}
						]
					}
				]
            },
			{
				element: <PrivateRoute />,
				children: [
					{
						element: <AuthorizedRoute allowedFor={Roles.Manager} />,
						children: [
							{ path: '/orders-print', element: <OrderPrintPage /> },
							{ path: '/orders-list-print', element: <OrderListPrintPage />, }
						],
					}
				]
			},
            { path: '/signin', element: <SignInPage />, },
			{ path: '/signup', element: <SignUpPage />, },
        ]
	},
	{
		element: <TelegramProvider />,
		children: [
			{ element: <TelegramLayout />,
				children: [
					{ path: '/telegram/courier', element: <TelegramOrderViewPage /> },
					{ path: '/telegram/courier/view', element: <TelegramOrderActionPage /> }
				]
			}
		]
	}
]

export default routes