import React, { useEffect, useState} from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {convertToTimeZone} from "@/common/utils/utils";
import {OrderItem} from "@/common/models/order";
import {Formats} from "@/common/constants/dateFormat";

const TimelineItem = ({ data } : TimelineItemProps) => {
	const { t } = useTranslation()
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const container: HTMLElement = document.querySelector('.timeline-container')
		const items = document.querySelectorAll('.timeline-item')
		if(container && items) {
			setHeight(items?.length * (items[0]?.scrollHeight + 10))
			container.style.maxHeight = `${items?.length * (items[0]?.scrollHeight + 10)}px`
		}
	}, []);

	return (
		<Container className="timeline-item">
			<style>{`
                .timeline-container::after {
                height: ${height ? `${height}px` : `570px`}};
                }
            `}</style>
			<div className="timeline-item-content">
				<span className="circle" />
				<time>{convertToTimeZone(data?.createDate, false, Formats.DATE_DMY_TIME)}</time>
				<p>{t(`orderStatus.${data.status}`)}</p>
			</div>
		</Container>
	)
}

interface TimelineItemProps {
	data: OrderItem,
}

export default TimelineItem