import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import QRCode from 'react-qr-code'

import './index.css'
import { OrderItem } from '@/common/models/order'
import { convertToTimeZone } from '@/common/utils/utils'
import { Formats } from '@/common/constants/dateFormat'

const OrderPrintPage = () => {
    const componentRef = useRef()
    const location = useLocation()
    const order: OrderItem = location.state?.orderItem
    const qrValue: string = JSON
        .stringify({ id: order.id, orderId: order.orderId })
        .replace(/"/g, '\\"')

    const getAddressWithStartBold = (address: string) => {
        const [firstPart, ...rest] = address.split(/,(.+)/)
        return (
            <span>
                <span className="font-bold">{firstPart}, </span>
                {rest}
            </span>
        )
    }

    useEffect(() => {
        if (order) {
            document.title = `Печать заказа №${order.id}`
            document.getElementById('print-button').click()
        }
    }, [order])

    if (!order) {
        return <div>No order details available.</div>
    }

    return (
      <div className="d-flex font-sticker" ref={componentRef}>
        <div className="d-flex flex-column justify-content-between body-sticker">
            <div className="d-flex">
                <div className="field-section space-right">
                    <div className="font-sticker-header">
                        <span>От кого</span>
                    </div>
                    <div className="delivery-party-info">
                        <div>
                            <span>{order.deliverySender.company.name}</span>
                        </div>
                        <div className="field">
                            {getAddressWithStartBold(order.deliverySender.address.addressString)}
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <span>{order.deliverySender.contactName && `${order.deliverySender.contactName}, `}{order.deliverySender.phone}</span>
                        </div>
                    </div>
                </div>
                <div className="field-section">
                    <div className="font-sticker-header">
                        <span>Кому</span>
                    </div>
                    <div className="delivery-party-info">
                        <div>
                            <span>{order.deliveryReceiver.company.name}</span>
                        </div>
                        <div className="field">
                            {getAddressWithStartBold(order.deliveryReceiver.address.addressString)}
                        </div>
                        <div className="field">
                            <span>{order.deliveryReceiver.contactName && `${order.deliveryReceiver.contactName}, `}{order.deliveryReceiver.phone}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="delivery-field-section">
                    <div className="d-flex">
                        <div className="field-section space-right">
                            <div className="font-sticker-header">
                                <span>Номер заказа</span>
                            </div>
                            <span>{order.id}</span>
                        </div>
                        <div className="field-section">
                            <div className="font-sticker-header">
                                <span>Номер доставки</span>
                            </div>
                            <span>{order.orderId}</span>
                        </div>
                    </div>
                    <div style={{ marginTop: '15px' }}>
                        <div className="field-section w-100">
                            <div className="font-sticker-header">
                                <span>Дата создания</span>
                            </div>
                            <div>
                                <span>{convertToTimeZone(order.createDate, false, Formats.DATE_DMY_TIME)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex" style={{ marginTop: '17px' }}>
                    <div className="font-sticker-section">
                        <QRCode
                            className="ms-auto"
                            size={100}
                            value={qrValue}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    <div className="d-flex align-items-end justify-content-end w-100">
                        <div>
                            <div className="d-flex justify-content-end font-sticker-header" style={{ marginBottom: '15px' }}>
                                <span>Маршрут</span>
                            </div>
                            <span className="font-sticker-route">{order.route}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="d-none">
            <ReactToPrint
                trigger={() => <button id="print-button">Print</button>}
                content={() => componentRef.current}
            />
        </div>
      </div>
    );
}

export default OrderPrintPage