export const orderStatusMap: OrderStatusParam = {
    /**
     * Positive branch.
     */
    'READY_FOR_PICKUP': {
        events: [
            {
                name: 'Курьер назначен',
                event: 'ASSIGN_PICKUP_COURIER',
            },
        ]
    },
    'DELIVERED_TO_WAREHOUSE': {
        events: [
            {
                name: 'Принят складом по количеству',
                event: 'WAREHOUSE_ACCEPT',
            },
            {
                name: 'Доставка не дошла до получателя',
                event: 'WAREHOUSE_DELIVERY_REPEATED',
            }
        ],
    },

    'ACCEPTED_BY_WAREHOUSE': {
        events: [
            {
                name: 'Начать маркировку',
                event: 'WAREHOUSE_BEGIN_MARKING',
            },
        ],
    },
    'MARKING_AT_WAREHOUSE': {
        events: [
            {
                name: 'Завершить маркировку',
                event: 'WAREHOUSE_FINISH_MARKING',
            },
        ],
    },
    'MARKED_AT_WAREHOUSE': {
        events: [
            {
                name: 'Начать сортировку',
                event: 'WAREHOUSE_BEGIN_SORTING',
            },
        ],
    },
    'SORTING_AT_WAREHOUSE': {
        events: [
            {
                name: 'Завершить сортировку',
                event: 'WAREHOUSE_FINISH_SORTING',
            },
        ],
    },
    'SORTED_AT_WAREHOUSE': {
        events: [
            {
                name: 'Готово к доставке',
                event: 'WAREHOUSE_CONFIRM_DELIVERY',
            }
        ],
    },
    'READY_FOR_DELIVERY': {
        events: [
            {
                name: 'Назначен курьер на доставку',
                event: 'WAREHOUSE_ASSIGN_DELIVERY_COURIER'
            }
        ]
    },
    'DELIVERED_BY_COURIER': {
        events: [
            {
                name: 'Подтвердить доставку',
                event: 'MANAGER_CONFIRM_DELIVERY'
            }
        ]
    },

    /**
     * Negative branch.
     */
    'PENDING_CLIENT_CLARIFICATION': {
        events: [
            {
                name: 'Курьер не успел',
                event: 'WAREHOUSE_DELIVERY_RETRY_ON_COURIER_FAULT',
            },
            {
                name: 'Клиента не было на месте',
                event: 'WAREHOUSE_DELIVERY_RETRY_ON_CLIENT_UNAVAILABLE',
            },
            {
                name: 'Неправильный адрес',
                event: 'WAREHOUSE_DELIVERY_RETRY_ON_INVALID_ADDRESS',
            },
            {
                name: 'Отправить на уничтожение',
                event: 'WAREHOUSE_DELIVERY_ABORTED_DESTROY',
            },
            {
                name: 'Вернуть отправителю',
                event: 'WAREHOUSE_DELIVERY_ABORTED_RETURN',
            }
        ]
    }
}

export interface OrderStatusParam {
    [status: string]: OrderAction
}

export interface OrderAction {
    events: OrderEventItem[],
}

export interface OrderEventItem {
    name: string,
    event: string,
}
