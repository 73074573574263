import {useEffect, useState} from 'react'
import {Badge, Button, Modal, Table} from "react-bootstrap"
import {FilterCircleFill, SortDownAlt, SortUpAlt} from "react-bootstrap-icons"
import {observer} from "mobx-react-lite"
import {useTranslation} from 'react-i18next'
import {t} from 'i18next'

import '../../../styles/common.css'
import { OrderItem } from "@/common/models/order"
import useSort from "@/common/hooks/useSort"
import {appendString, buildDeliveryPartyString, convertToTimeZone} from "@/common/utils/utils"
import OrderItemDetailsView from './OrderItemDetailsView'
import OrderActionDropdown from "@/components/controls/order-action/OrderActionDropdown"
import OrderService from '@/common/api/OrderService'
import useAuth from "@/common/hooks/useAuth";
import useOrderSearch from "@/common/hooks/useOrderSearch";
import {Formats} from "@/common/constants/dateFormat";

const OrdersContentTable = observer(({ data, expanded = true, onAnyChange = () => {} }: OrdersListProps) => {
    const { t } = useTranslation()
    
    const {persistedSortOptions, persistSortOptions} = useOrderSearch()
    const { sortedData, onSortChange, getSortDirection } = useSort<OrderItem>(data, persistedSortOptions, persistSortOptions)
    const [show, setShow] = useState(false);
    const [selectedOrderItem, setSelectedOrderItem] = useState(null);
    const {user} = useAuth()

    const handleShow = (orderItem) => {
        setSelectedOrderItem(orderItem);
        setShow(true);
    };

    const handleClose = () => {
        setSelectedOrderItem(null);
        setShow(false);
    };

    return (
        <div>
            <Table bordered striped size="sm">
                <thead>
                {expanded ? (
                    <tr>
                        <th>№</th>
                        <th onClick={() => onSortChange('createDate')}>
                            Создан
                            <SortColumn
                                key="createDate"
                                direction={getSortDirection('createDate')}
                            />
                        </th>
                        <th>Номер накладной</th>
                        <th>Отправитель</th>
                        <th>Контакты отправителя</th>
                        <th>Получатель</th>
                        <th>Контакты получателя</th>
                        <th onClick={() => onSortChange('courier', 'firstName')}>
                            Курьер
                            <SortColumn
                                key="courier"
                                direction={getSortDirection('courier')}
                            />
                        </th>
                        <th onClick={() => onSortChange('zone')}>
                            Маршрут
                            <SortColumn
                                key="zone"
                                direction={getSortDirection('zone')}
                            />
                        </th>
                        <th onClick={() => onSortChange('cell')}>
                            Ячейка
                            <SortColumn
                                key="cell"
                                direction={getSortDirection('cell')}
                            />
                        </th>
                        <th onClick={() => onSortChange('estimatedDeliveryDate')}>
                            Предварительная дата доставки
                            <SortColumn
                                key="estimatedDeliveryDate"
                                direction={getSortDirection('estimatedDeliveryDate')}
                            />
                        </th>
                        <th>Детали заказа</th>
                        <th onClick={() => onSortChange('status')}>
                            Статус
                            <SortColumn
                                key="status"
                                direction={getSortDirection('status')}
                            />
                        </th>
                    </tr>
                ) : (
                    <tr>
                        <th>№</th>
                        <th onClick={() => onSortChange('createDate')}>
                            Создан
                            <SortColumn
                                key="createDate"
                                direction={getSortDirection('createDate')}
                            />
                        </th>
                        <th>Получатель (адрес, организация)</th>
                        <th onClick={() => onSortChange('parcelType')}>
                            Тип
                            <SortColumn
                                key="parcelType"
                                direction={getSortDirection('parcelType')}
                            />
                        </th>
                        <th onClick={() => onSortChange('deliveryType')}>
                            Доставка
                            <SortColumn
                                key="deliveryType"
                                direction={getSortDirection('deliveryType')}
                            />
                        </th>
                        <th onClick={() => onSortChange('estimatedDeliveryDate')}>
                            Примерная дата доставки
                            <SortColumn
                                key="estimatedDeliveryDate"
                                direction={getSortDirection('estimatedDeliveryDate')}
                            />
                        </th>
                        <th onClick={() => onSortChange('status')}>
                            Статус
                            <SortColumn
                                key="status"
                                direction={getSortDirection('status')}
                            />
                        </th>
                    </tr>
                )}
                </thead>
                <tbody>
                {expanded ? (
                    sortedData.map((order, index) => (
                        <OrderStatefulRow
                            key={index}
                            order={order}
                            onDetailsClick={() => handleShow(order)}
                            onAnyChange={onAnyChange}
                        />
                    ))
                ) : (
                    sortedData.map((order, index) => (
                        <tr key={index} onClick={() => handleShow(order)} >
                            <td>{order.id}</td>
                            <td>{order?.createDate && convertToTimeZone(order?.createDate, false,Formats.DATE_DMY_TIME)}</td>
                            <td>{buildDeliveryPartyString(order?.deliveryReceiver)}</td>
                            <td>{t(`parcelType.${order.parcelType}`)}</td>
                            <td>{t(`deliveryType.${order.deliveryType}`)}</td>
                            <td>{order.estimatedDeliveryDate && convertToTimeZone(order?.estimatedDeliveryDate, false,Formats.DATE_DMY_TIME)}</td>
                            <td>{t(`orderStatus.${order.status}`)}</td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
            <Modal show={show} onHide={handleClose} dialogClassName="wide-modal">
                <Modal.Header className="d-flex justify-content-between">
                    <Modal.Title className="pl-5">Детали заказа {selectedOrderItem?.id}</Modal.Title>
                    <Button variant="light" onClick={handleClose}>
                        X
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <OrderItemDetailsView onEdit={onAnyChange} orderItem={selectedOrderItem} role={user.roles[0]}/>
                </Modal.Body>
            </Modal>
        </div>
    )
})

const SortColumn = ({ direction }: SortColumnProps) => {

    const SortDirection = () => {
        if (!direction) {
            return
        }

        return direction === 'asc'
            ? <SortUpAlt />
            : <SortDownAlt />
    }

    return (
        <div className="d-flex align-items-center cursor">
            <FilterCircleFill />
            <span className="px-1"></span>
            <SortDirection />
        </div>
    )
}

const OrderStatefulRow = ({ order, onDetailsClick = () => {}, onAnyChange = () => {} }: OrderStatefulRowProps) => {
    const [orderStatus, setOrderStatus] = useState<string | null>(order.status)

    const [isActionLoading, setIsActionLoading] = useState<boolean>(false)
    const [actionError, setActionError] = useState()
    const [isActionSuccess, setIsActionSuccess] = useState<boolean>(false)

    useEffect(() => {
        setOrderStatus(order.status)
    }, [order])

    return (
        <tr>
            <td>
                <span>{order.id}</span>
                <div className="position-relative">
                    <Badge
                        className="bg-light text-dark cursor"
                        onClick={() => onDetailsClick()}
                        pill
                    >
                        Детали
                    </Badge>
                    <OrderActionDropdown
                        status={orderStatus}
                        error={actionError}
                        isLoading={isActionLoading}
                        isSuccess={isActionSuccess}
                        onClick={(event) => {
                            setIsActionLoading(true)
                            setActionError(null)
                            OrderService.changeStatus(order.id, event)
                                .then((newStatus) => {
                                    setOrderStatus(newStatus)
                                    setIsActionSuccess(true)
                                    onAnyChange()
                                })
                                .catch((e) => {
                                    setActionError(e)
                                    setIsActionSuccess(false)
                                })
                                .finally(() => setIsActionLoading(false))
                        }}
                    />
                </div>
            </td>
            <td>{order?.createDate && convertToTimeZone(order?.createDate, false,Formats.DATE_DMY_TIME)}</td>
            <td>{order.orderNumber}</td>
            <td>{buildDeliveryPartyString(order?.deliverySender)}</td>
            <td>{appendString(order?.deliverySender?.contactName, order?.deliverySender?.phone)}</td>
            <td>{buildDeliveryPartyString(order?.deliveryReceiver)}</td>
            <td>{appendString(order?.deliveryReceiver?.contactName, order?.deliveryReceiver?.phone)}</td>
            <td>{appendString(order?.courier?.lastName, order?.courier?.firstName)}</td>
            <td>{order.route}</td>
            <td>{order.cell}</td>
            <td>{order?.estimatedDeliveryDate && convertToTimeZone(order?.estimatedDeliveryDate, false,Formats.DATE_DMY_TIME)}</td>
            <td>{order.comments}</td>
            <td>{t(`orderStatus.${orderStatus}`)}</td>
        </tr>
    )
}

interface OrdersListProps {
    data: OrderItem[],
    expanded?: boolean,
    onAnyChange: () => void,
}

interface SortColumnProps {
    key: string,
    direction: string,
}

interface OrderStatefulRowProps {
    order: OrderItem,
    onDetailsClick: () => void,
    onAnyChange: () => void,
}

export default OrdersContentTable
