import React, { useEffect, useRef, useState } from 'react'
import { Alert, Badge, Spinner } from 'react-bootstrap'

import { orderStatusMap, OrderAction } from './actions'
import Heading from '@/components/typography/Heading'
import { CheckCircleFill } from 'react-bootstrap-icons'

const OrderActionDropdown = ({ status, error, isLoading = false, isSuccess = false, disabled = false, onClick = () => {} }: OrderActionDropdownProps) => {
    const [show, setShow] = useState<boolean>(false)
    const [orderAction, setOrderAction] = useState<OrderAction | null>(orderStatusMap[status])
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShow(false)
        }
    }

    useEffect(() => {
        setOrderAction(orderStatusMap[status] || null);

        if (show) {
            document.addEventListener('click', handleClickOutside)
        } else {
            document.removeEventListener('click', handleClickOutside)
        }

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [status, show])

    if (!orderAction || orderAction.events.length < 1) {
        return
    }

    return (
        <div className="position-relative" ref={dropdownRef}>
            <Badge className="cursor" pill onClick={() => setShow(!show)}>
                Действия
            </Badge>
            {show && (
                <div
                    className="position-absolute bg-white border border-rounded-1 z-3"
                    style={{ minWidth: '250px' }}
                >
                    <div className="d-flex p-2">
                        <Heading size="sm">Выберите действие</Heading>
                        <div className="d-flex align-items-center ms-auto">
                            {isLoading && (
                                <Spinner size="sm" />
                            )}
                            {isSuccess && !isLoading && (
                                <CheckCircleFill className="text-success" />
                            )}
                        </div>
                    </div>
                    {error && (
                        <div className="px-2">
                            <Alert className="border-rounded-1 p-2" variant="danger">
                                <span>{error.response?.body?.errorMessage}</span>
                            </Alert>
                        </div>
                    )}
                    <div
                        className={`d-flex p-2 flex-column ${disabled && 'opacity-50'}`}
                    >
                        {orderAction.events.map((value, index) => (
                            <div className="pt-2">
                                <OrderActionDropdownItem
                                    key={index}
                                    event={value.event}
                                    text={value.name}
                                    disabled={disabled || isLoading}
                                    onClick={(event) => {
                                        if (!disabled) {
                                            onClick(event)
                                        }
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

interface OrderActionDropdownProps {
    status: string,
    disabled?: boolean,
    error?: any,
    isSuccess?: boolean,
    isLoading?: boolean,
    onClick: (event: string) => void,
}

const OrderActionDropdownItem = ({ event, text, disabled = false, onClick }: OrderActionDropdownItemProps) => {
    return (
        <div onClick={() => onClick(event)} className={`bg-light border-rounded-1 p-2 ${!disabled && 'cursor'}`}>
            {text}
        </div>
    )
}

interface OrderActionDropdownItemProps {
    event: string,
    text: string,
    disabled?: boolean,
    onClick: (event: string) => void,
}

export default OrderActionDropdown